import React, { useState, useRef, useLayoutEffect } from 'react';
import cn from 'classnames';
import { usePopper } from 'react-popper';
import { Button, SvgIcon } from '@ui-elements';
import Carousel, { ResponsiveType } from 'react-multi-carousel';
import { Articles, HomepageArticlesProps } from './store';
import styles from './index.module.scss';

export const HomepageArticles = React.memo((props: HomepageArticlesProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [referenceElement, setReferenceElement] =
    useState<HTMLElement | null>();
  const [isTwoColumns, setIsTwoColumns] = useState(false);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>();
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>();
  const [tooltipSize, setTooltipSize] = useState('h3');
  const { styles: popperStyles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
      placement: 'auto-end',
    }
  );

  const showTooltip = () => {
    popperElement?.setAttribute('data-show', 'true');
  };

  const hideTooltip = () => {
    popperElement?.removeAttribute('data-show');
  };

  const centerMode = React.useMemo(() => props.articles?.length < 3, []);

  const responsive: ResponsiveType = {
    desktop: {
      breakpoint: { max: 3000, min: 1280 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1279, min: 992 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 991, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  useLayoutEffect(() => {
    if (containerRef.current !== null) {
      if (
        containerRef.current?.clientWidth > 991 &&
        containerRef.current?.clientWidth < 1280
      ) {
        setIsTwoColumns(true);
      }
    }

    const headingHtml = document.getElementById('headingText')?.innerHTML || '';
    const size = headingHtml.substring(
      headingHtml.indexOf('<') + 1,
      headingHtml.indexOf('>')
    );
    setTooltipSize(size || '');
  }, []);

  return (
    <div
      ref={containerRef}
      className={cn(styles.homepageArticlesSection, 'container')}
    >
      <div className={styles.homepageArticlesHeading}>
        <span
          id="headingText"
          dangerouslySetInnerHTML={{ __html: props.articlesHeading }}
        />

        {!!props.articlesHeadingTooltip && (
          <>
            <span
              ref={setReferenceElement}
              onMouseEnter={showTooltip}
              onMouseLeave={hideTooltip}
              className={cn(styles.disclaimerTrigger, tooltipSize)}
              style={{
                color: `#154e97`,
                fontSize: tooltipSize === 'h2' ? `18px` : `16px`,
                fontWeight: 600,
                paddingTop: tooltipSize === 'h2' ? `10px` : undefined,
              }}
            >
              *
            </span>
            <div
              ref={setPopperElement}
              style={popperStyles.popper}
              {...attributes.popper}
              className={styles.disclaimerTooltip}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: props.articlesHeadingTooltip,
                }}
              />
              <div ref={setArrowElement} style={popperStyles.arrow} />
            </div>
          </>
        )}
      </div>

      {isTwoColumns ? (
        <div>
          <Carousel
            partialVisible={false}
            swipeable
            arrows={isTwoColumns}
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
            responsive={responsive}
            containerClass={cn(styles.carouselContainer)}
            itemClass={styles.carouselItem}
            centerMode={centerMode}
            autoPlaySpeed={3000}
            transitionDuration={500}
          >
            {props.articles?.map((article, index) => (
              <HomepageArticle key={index} {...article} />
            ))}
          </Carousel>
        </div>
      ) : (
        <div className={styles.homepageArticlesGridContainer}>
          {props.articles?.map((article, index) => (
            <HomepageArticle key={index} {...article} />
          ))}
        </div>
      )}
    </div>
  );
});

interface ArrowsProps {
  onClick?: () => void;
}
const CustomLeftArrow = ({ onClick }: ArrowsProps) => {
  return (
    <SvgIcon
      className={styles.customLeftArrow}
      type="chevronLeft"
      size={2}
      onClick={onClick}
    />
  );
};

const CustomRightArrow = ({ onClick }: ArrowsProps) => {
  return (
    <SvgIcon
      className={styles.customRightArrow}
      type="chevronRight"
      size={2}
      onClick={onClick}
    />
  );
};

export const HomepageArticle = React.memo((props: Articles) => {
  const authorName = props.showAuthorName ? props.authorName : '';
  const readingTime = props.showReadingTime ? props.readingTime : '';

  return (
    <div
      className={styles.homepageArticle}
      onClick={() =>
        window.open(
          props?.articleCTA?.url,
          props?.articleCTA?.target || '_self'
        )
      }
    >
      <div className={styles.articleImageContainer}>
        <img src={props.imageSrc} className={styles.articleImage} />
        {!!props.imageDisclaimer && (
          <span
            className={styles.articleImageDisclaimer}
            dangerouslySetInnerHTML={{ __html: props.imageDisclaimer }}
          />
        )}
      </div>

      <div className={styles.articleContent}>
        <div
          className={styles.articleTitle}
          dangerouslySetInnerHTML={{ __html: props.articleTitle }}
        />
        <div className={styles.articleContentBottom}>
          <div className={styles.articleCTA}>
            <div className={styles.ctaWrapper}>
              {!!props.articleCTA && (
                <Button
                  type="secondary"
                  target={props?.articleCTA?.target || '_self'}
                  href={props?.articleCTA?.url}
                  className={styles.ctaButton}
                >
                  {props?.articleCTA?.name}
                </Button>
              )}
            </div>
          </div>
          <div className={styles.articleDetails}>
            <span>{authorName}&nbsp;</span>
            <span>{authorName && readingTime ? `|` : ``}</span>
            <span className={styles.readingTime}>&nbsp;{readingTime}</span>
          </div>
        </div>
      </div>
    </div>
  );
});
